import React from 'react'
import video from "../../assets/videos/card6video.mp4"
import "./Video.css"
const Video = () => {
  return (
    <div>
         <video autoPlay loop muted className="video" >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default Video