import React, { useState, useEffect } from 'react';
import './Projects.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Projects = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRightSide, setIsRightSide] = useState(true);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const handleMouseMove = (e) => {
    const middle = window.innerWidth / 2;
    setIsRightSide(e.clientX > middle);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="slider">
      {isRightSide ? (
        <div className="right-arrow" onClick={goToNext}>
          <ArrowForwardIosIcon style={{ fontSize: 75, color: 'white' }} />
        </div>
      ) : (
        <div className="left-arrow" onClick={goToPrevious}>
          <ArrowBackIosNewIcon style={{ fontSize: 75, color: 'white' }} />
        </div>
      )}
      <div
        className="slide"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      ></div>
    </div>
  );
};

export default Projects;
