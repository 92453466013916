import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home"
import Grid from './pages/Grid/Grid';
import MainPage from './pages/Grid/gridComponents/MainPage';
import CarDealership from './components/CarDealership/CarDealership';


function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/grid' element={<MainPage />} />
        <Route path='/dealer' element={<CarDealership />} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;
