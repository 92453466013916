import { Email, Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material'
import React from 'react'
import "./Footer.css"
const Footer = () => {
    return (
        <div className='footer'>
            <h4>Shivam Sharma, LLC</h4>
            <div className="icons-footer">
            <div className="">  <Email /></div>
            <div className="">  <Twitter /></div>
            <div className=""> <Instagram /></div>
            <div className="">    <LinkedIn /></div>
            <div className="">  <Facebook /></div>
            </div>
        </div>
    )
}

export default Footer