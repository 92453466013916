import React, { useEffect } from "react";

import "../Grid.css"
import Logo from "../../../components/Logo/Logo"
import Article from "../../../components/Article/Article"
import Hello from '../../../components/Hello/Hello'
import SocialLinks from "../../../components/SocialLinks/SocialLinks"
import Slider from "../../../components/Slider/Slider"
import Video from "../../../components/Video/Video"
import AOS from 'aos';
import 'aos/dist/aos.css'
import PinnedProject from "../../../components/PinnedProject/PinnedProject";
import About from "../../../components/About/About";
import Projects from "../../../components/Projects/Projects";
import Footer from "../../../components/Footer/Footer";
import CarDealership from "../../../components/CarDealership/CarDealership";
const MainPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  })
  const images = [
    'https://assets.website-files.com/5f6906dba6c72aaec6e27a06/5f6906dba6c72ae785e27a4a_VOYCE-ABAN%402x.png',
    'https://assets.website-files.com/5f6906dba6c72aaec6e27a06/5f6906dba6c72a251ee27a8c_voyce-2%401x.png',
    'https://assets.website-files.com/5f6906dba6c72aaec6e27a06/5f6906dba6c72a26d3e27a41_Voyce%20Styling%20-%20wide2752.jpg',
    'https://assets.website-files.com/5f6906dba6c72aaec6e27a06/5f6906dba6c72a8851e27a35_voyce-5%402x.png',
    'https://assets.website-files.com/5f6906dba6c72aaec6e27a06/5f6906dba6c72a26d3e27a41_Voyce%20Styling%20-%20wide2752.jpg',
  ];
  return (
    <div className="portfolio-main">
      <div class="container">
        <div className="l gap" data-aos="fade-right"><Logo /></div>
        <div className="a gap" data-aos="fade-down"><Article /></div>
        <div className="h gap" data-aos="fade-left"><Hello /></div>
        <div className="s gap" data-aos="fade-up"><SocialLinks /></div>
        <div className="p gap" data-aos="fade-left"><Slider /></div>
        <div className="v gap" data-aos="fade-right"><Video /></div>
      </div>
      <div className="" data-aos="fade-up"><PinnedProject/></div>
    <div className=""><About/></div>
    <div className=""data-aos="fade-down"><Projects images={images}/></div>
    <div className=""><CarDealership/></div>
    <div className=""data-aos="zoom-in"><Footer/></div>
    </div>

  );
};

export default MainPage;
