import React, { useEffect } from 'react'
import "./PinnedProject.css"
import thread1 from "../../assets/projects-images/thread1.png"
import thread2 from "../../assets/projects-images/thread2.png"
import AOS from 'aos';
import 'aos/dist/aos.css'
const PinnedProject = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  })
  return (
    <div className='pinnedProject'>
        <div className="left">
            <button>THREADS</button>
            <h2>Global Design and Product Experience</h2>
            <p>Design direction lead on the future social experience for threads that includes , 
            Gamification,  Influencer Program, dating and Enhanced Analytics. This work is ongoing.</p>
        </div>
        <div className="center thread" data-aos="fade-up">
            <img src={thread1} alt="" srcset="" />
        </div>
        <div className="right thread" data-aos="fade-down">
            <img src={thread2} alt="" srcset="" />
        </div>
    </div>
  )
}

export default PinnedProject