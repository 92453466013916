import React, { useEffect } from 'react'
import "./About.css"
// import man from "../../assets/artistic-man.png"
import man from "../../assets/projects-images/shivam.png"
import Carousel from "../AboutContentSlider/Carousel"
import {countries} from "../AboutContentSlider/Data"
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const About = () => {
    useEffect(()=>{
        AOS.init({duration:1000});
      },[]);
    return (
        <div className='about'>
            <div className="about-left" data-aos="fade-right">
                <div className="about-image">
                    <img src={man} alt="" srcset="" />
                </div>
                <div className="about-content">
                    <h3>Behind the Work</h3>
                    <p>Specializes in visual design and ux strategy for design systems, digital products,
                        mobile applications and desktop platforms.</p>
                    <p>Stefan has an unusual multi-disciplinary background that he brings to the work today. A native Floridian, he attended Ringling College of Art & Design where he studied fine art, illustration, sculpture and design communication.
                        First starting at Robrady Design, a product and industrial design company and then at C.S.A.
                        Design in Minneapolis, working alongside the renown Charles S. Anderson.</p>
                    <p>Stefan held the position of Creative Director at Discovery Channel and Science Channel, where he was responsible for all disciplines of work including print, outdoor, interactive, social media and working seamlessly with the broadcast team to ensure creative consistency across all platforms.
                        His acclaimed work on brands such as Deadliest Catch, Dirty Jobs, Mythbusters,
                        Planet Earth and Shark Week has reached a global audience in 170 countries.</p>
                    <p>Prior to founding Poulos Collective, he was Executive Creative Director at Pappas Group where he led the work for Virgin America,
                        Voyce, Syfy, Geico, African Wildlife Foundation and SpaghettiOs.</p>
                </div>
            </div>
            <div className="about-right" >
                <div className="about-content-slider" data-aos="fade-left">
                    <Carousel images={countries}/>
                </div>
                <div className="about-content-video" data-aos="fade-left">
                    
                </div>
            </div>
        </div>
    )
}

export default About