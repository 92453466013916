import React from 'react'
import "./Hello.css"
import WavingHandIcon from '@mui/icons-material/WavingHand';
const Hello = () => {
  return (
      <div className='hello'>
          <h1>HELLO <WavingHandIcon className="custom-icon"/></h1>
          <p>Collection of shivam sharma
              who is specializes in visual design and UX strategy for design systems,
              mobile applications, and desktop platforms.</p>
      </div>
  )
}

export default Hello