import React from 'react';
import './Article.css';
import dragon from "../../assets/dragon-article.jpg"
import toronto from "../../assets/projects-images/toronto.png"
import blueToronto from "../../assets/projects-images/blue-toronto.png"
import article from "../../assets/projects-images/article-image.png"
const Article = () => {
  return (
    <div className="articleCard">
      <img src={article} alt="articleCard background" />
      <div className="text">INDIVIDUAL CREATOR</div>
      <button>ARTICLE</button>
    </div>
  );
};

export default Article;
