import React from 'react'
import "./SocialLinks.css"
import { Email, Facebook, GitHub, Instagram, LinkedIn, WhatsApp } from '@mui/icons-material'

const SocialLinks = () => {
    return (
        <div className='socialLinks'>
            <div className="links">
                <LinkedIn className='link linkedIn' />
                <GitHub className='link github' />
                <Instagram className='link instagram' />
            </div>
            <div className="links">
                <Facebook className='link facebook' />
                <Email className='link email' />
                <WhatsApp className='link whatsapp' />
            </div>
        </div>
    )
}

export default SocialLinks