export const countries = [
  {
    image: "/images/brazil.jpg",
    title: "Brazil",
  },
  {
    image: "/images/china.jpg",
    title: "China",
  },
  {
    image: "/images/france.jpg",
    title: "France",
  },
  {
    image: "/images/japan.jpg",
    title: "Japan",
  },
  {
    image: "/images/norway.jpg",
    title: "Norway",
  },
];
