import React, { useState, useEffect } from 'react';
import './Slider.css';
import item1 from "../../images/item1.png"
import item2 from "../../images/item2.png"
import item3 from "../../images/item3.png"
import man from "../../images/artistic-man.png"

const Slider = () => {
    const [active, setActive] = useState(0);
    const [zIndex, setZIndex] = useState(2);

    const list = [
        { imgSrc: item1, bgColor: '#428372', bgimage:"https://s3-alpha.figma.com/hub/file/2627191634/41017c0c-b04e-4e2c-bc1d-011599319cac-cover.png", title: 'Shopping Cart', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum id, voluptatem error aspernatur incidunt quas sit, dolores voluptates delectus odit quo, repudiandae nam sint eligendi aliquid corrupti architecto earum. Reprehenderit nobis nihil excepturi eius, quas distinctio saepe aspernatur voluptatum quam veritatis accusantium dolorem non fuga corrupti, quia possimus minus? Amet!' },
        { imgSrc: item2, bgColor: '#EEAA19', bgimage:"https://imgproxy.epicpxls.com/P9LNbDxqBsftcrCN9LWpXFkbkzQac4U8peYXxhBydFE/rs:fill:800:600:0/g:no/aHR0cHM6Ly9pdGVt/cy5lcGljcHhscy5j/b20vdXBsb2Fkcy9w/aG90by80NjRjODk5/OTg2NzU1YWY0MmRj/ZmU3NTA1YWFiODcx/YQ.jpg", title: 'Threads', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum id, voluptatem error aspernatur incidunt quas sit, dolores voluptates delectus odit quo, repudiandae nam sint eligendi aliquid corrupti architecto earum. Reprehenderit nobis nihil excepturi eius, quas distinctio saepe aspernatur voluptatum quam veritatis accusantium dolorem non fuga corrupti, quia possimus minus? Amet!' },
        { imgSrc: item3, bgColor: '#e86c3f', bgimage:"https://images01.nicepagecdn.com/page/53/18/website-template-preview-53183.jpg", title: 'PICS', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum id, voluptatem error aspernatur incidunt quas sit, dolores voluptates delectus odit quo, repudiandae nam sint eligendi aliquid corrupti architecto earum. Reprehenderit nobis nihil excepturi eius, quas distinctio saepe aspernatur voluptatum quam veritatis accusantium dolorem non fuga corrupti, quia possimus minus? Amet!' }
    ];

    const lastPosition = list.length - 1;

    const nextSlide = () => {
        const newValue = active + 1 > lastPosition ? 0 : active + 1;
        setActive(newValue);
        showSlider('next');
    }

    const prevSlide = () => {
        const newValue = active - 1 < 0 ? lastPosition : active - 1;
        setActive(newValue);
        showSlider('prev');
    }

    const setItemActive = (newValue, callbackFunction) => {
        if (newValue === active) return;
        setActive(newValue);
        callbackFunction();
    }

    const showSlider = (type) => {
        const itemActiveOld = document.querySelector('.carousel .list .item.active');
        const dotActiveOld = document.querySelector('.dots li.active');

        if (itemActiveOld) itemActiveOld.classList.remove('active');

        if (type === 'next') {
            setZIndex(zIndex + 1);
        }

        setTimeout(() => {
            setZIndex(2);
        }, 1500);
    }

    useEffect(() => {
        const autoRun = setTimeout(() => {
            nextSlide();
        }, 5000);

        return () => clearTimeout(autoRun);
    }, [active]);

    return (
        <div className="carousel">
            <div className="list">
                {list.map((item, index) => (
                    <div key={index} className={`item ${index === active ? 'active' : ''}`} style={{ '--img-src': `url(${item.imgSrc})`,'--bg-color': item.bgColor, '--title': `'${item.title}'`, zIndex: index === active ? zIndex : null }}>
                        <div className="content">
                            <div className="image"></div>
                            <div className="info">
                                <div className="title">{item.title}</div>
                                <div className="category">Description</div>
                                <div className="des">{item.description}</div>
                                <a href="#">See More <span>&#8594;</span></a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="arrows">
                <button id="prev" onClick={prevSlide}>{'<'}</button>
                <button id="next" onClick={nextSlide}>{'>'}</button>
            </div>
            <ul className="dots">
                {list.map((_, index) => (
                    <li key={index} className={index === active ? 'active' : ''}></li>
                ))}
            </ul>
        </div>
    );
}

export default Slider;
