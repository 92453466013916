import React from 'react'
// import logo from "../../assets/projects-images/logoImage.jpg"
// import logo from "../../assets/projects-images/vr-image.jpg"
// import logo from "../../assets/projects-images/deer.jpg"
// import logo from "../../assets/projects-images/image.png"
import logo from "../../assets/projects-images/shivam-logo.png"
import "./Logo.css"
const Logo = () => {
  return (
    <div className="logo">
        {/* <img src={logo} alt="" srcset="" /> */}
    </div>
  )
}

export default Logo