import React, { useEffect } from 'react';
import "./CarDealership.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import carImage from "./CarDealerImages/carDealer.png";
import phoneWifi from "./CarDealerImages/phoneWifi.png"

const CarDealership = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
      })
    useEffect(() => {

        const handleScroll = () => {
            const carObject = document.querySelector('.carObject img');
            const scrollPosition = window.pageYOffset;
            const maxScroll = document.body.scrollHeight - window.innerHeight;
            let translateX;
        
            // Calculate the translation based on the scroll position
            if (window.innerWidth < 768) {
                // For mobile devices, adjust the translation differently
                const startX = 100; // start at 100% from the right
                const endX = 0; // end at 60% from the right
                translateX = startX - (startX - endX) * (scrollPosition / maxScroll);
            } else {
                // For larger screens, move the car object horizontally
                const startX = 100; // start at 100% from the right
                const endX = 60; // end at 60% from the right
                translateX = startX - (startX - endX) * (scrollPosition / maxScroll);
            }
        
            carObject.style.transform = `translateX(${translateX}%)`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="">
            {/* <div className="spacer"></div> */}
            <div className='carDealership'>
                <div className="leftCar">
                    <div className="carHeading">
                        <button>TEAM @shivam.net</button>
                        <h1>Automotive Dealership <br />Platform Redesign</h1>
                        <p>A uniquely personal online car buying experience.</p>
                    </div>
                    <div className="carObject">
                        <img src={carImage} alt="Car" />
                    </div>
                </div>
                <div className=" leftCar rightCar">
                    <div className="carHeading">
                        <button>TEAM @shivam.net</button>
                        <h1>Augmented Reality Home<br /> Wifi Tool</h1>
                        <p>Design and UX strategy for “Virtual Tech” self help app <br /> that allows homeowners to diagnose wireless issues.</p>
                    </div>
                    <div className="carObject" data-aos="fade-up">
                        <img src={phoneWifi} alt="Car" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarDealership;
